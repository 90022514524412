import CryptoJS from 'crypto-js';

/**
 * AES加密
 * @param word
 * @param keyStr
 * @returns {string}
 */
export const encrypt = (word, keyStr) =>{
  var key = CryptoJS.enc.Utf8.parse(keyStr)
  var srcs = CryptoJS.enc.Utf8.parse(JSON.stringify(word))
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7})
  return encrypted.toString()
}

/**
 * AES解密
 * @param word
 * @param keyStr
 * @returns {*}
 */
export const decrypt = (word, keyStr) => {
  var key = CryptoJS.enc.Utf8.parse(keyStr)// Latin1 w8m31+Yy/Nw6thPsMpO5fg==
  var decrypt = CryptoJS.AES.decrypt(word.data, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7})
  return CryptoJS.enc.Utf8.stringify(decrypt).toString()
}
