/** 网络请求提示语
 * key:{SYSTEM_RESPONSE_MESSAGE}
 * @type {{SERVICE_NOT_IMPLEMENTED: string,
 * NO_ACCESS_RIGHTS: string,
 * CONNECTION_ERROR: string,
 * LOGIN_EXPIRE: string,
 * THE_HTTP_VERSION_IS_NOT_SUPPORTED: string,
 * THE_REQUEST_TIMEOUT: string,
 * SERVER_ERROR: string,
 * FAILED_TO_CONNECT_TO_SERVER: string,
 * NETWORK_ERROR: string,
 * SERVICE_UNAVAILABLE: string,
 * ACCESS_ERROR: string,
 * REQUEST_ERROR: string,
 * NETWORK_TIMEOUT: string}}
 */
export const SYSTEM_RESPONSE_MESSAGE = {
    REQUEST_ERROR:'请求错误(400)',//请求错误(400)
    LOGIN_EXPIRE:'登录已过期，请重新登录！',//登录已过期(401)
    NO_ACCESS_RIGHTS:'无访问权限',//无访问权限(403)
    ACCESS_ERROR:'请求出错(404)',//请求出错(404)
    THE_REQUEST_TIMEOUT:'请求超时',//请求超时(408)
    SERVER_ERROR:'服务器错误',//服务器错误(500)
    SERVICE_NOT_IMPLEMENTED:'服务未实现',//服务未实现(501)
    NETWORK_ERROR:'网络错误',//网络错误(502)
    SERVICE_UNAVAILABLE:'服务不可用',//服务不可用(503)
    NETWORK_TIMEOUT:'网络超时',//网络超时(504)
    THE_HTTP_VERSION_IS_NOT_SUPPORTED:'HTTP版本不受支持',//HTTP版本不受支持(505)
    CONNECTION_ERROR:'连接出错',//连接出错 status 未知
    FAILED_TO_CONNECT_TO_SERVER:'连接服务器失败'//连接服务器失败
}